// Init jQuery
const $ = require("jquery");
window.jQuery = $;
window.$ = $;
require('popper.js');

// Init jquery-form
require("jquery-form");

// Init Boostrap
import 'bootstrap';

// Init Bootstrap icons
require('bootstrap-icons/font/bootstrap-icons.scss');

// Some global JS
document.addEventListener('DOMContentLoaded', function () {
    document.getElementById('sidebarCollapse')?.addEventListener('click', function () {
        document.getElementById('sidebar')?.classList.toggle('active');
    });
});
